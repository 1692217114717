<template>
  <v-container class="bg-glass-effect px-3 pt-10" fluid>
     <BackArrow style="position: initial"/>
    <v-row  class="px-3">
      <v-col class="pt-7 pb-0" cols="12">
        <h1 class="generic-title ">Meus pagamentos</h1>
      </v-col>
      <v-col>
        <h3 class="mt-5 mb-8 font-weight-black">Telemedicina</h3>
          <h4 class="mt-5 mb-2 font-weight-black">Histórico de pagamento</h4>

        <v-col v-for="(item,index) in transactions" :key="index">
          <v-divider class="white mt-2"></v-divider>
          <v-row col-12 class="mt-5">
            <v-row col-6 class="ml-2 align-center justify-content-start">
              <div col-1 class="mr-4">
                <v-icon v-if="item.status === 'denied' || item.status === 'notSend' || item.status === 'processError'" color="#D9FF00FF">fas fa-exclamation-circle </v-icon>
                <v-icon v-if="item.status === 'captured' || item.status === 'authorized'" color="green darken-2">fas fa-check-circle</v-icon>
              </div>
              <div col-3>
                <h4 class="  font-weight-black">{{item.date}}</h4>
              </div>
            </v-row>
            <v-row  col-6 class="mr-2 align-center justify-end" style="margin: 0">
              <div col-3>
                <h4 class="mr-4 font-weight-black">R$ {{ item.value }}</h4>
              </div>
               <div col-1>
                <v-btn
                  color="secondary"
                  x-small
                  dark
                  @click="$router.push({name: 'payment-detail', params: {transaction: item}, query: {transactionId: item.id}})"
                >
                  <v-icon small color="blue darken-2">fas fa-search</v-icon>
                </v-btn>
              </div>
            </v-row>
          </v-row>
        </v-col>

      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import BackArrow from "@/components/covid/back-arrow";
import SubscriptionService from "@/services/odoo/SubscriptionService";
import moment from "moment";


export default {
  data: () => ({
    transactions: [],
    subscriptionId: null
  }),
 components: {
    BackArrow,
    //GenericBtn,
    //DatePicker
  },
  mounted() {
    if(this.$route.params && this.$route.query.subscriptionId) {
      this.subscriptionId = this.$route.query.subscriptionId;
    }
    this.subscriptionService = new SubscriptionService();
    this.getTransactionList();
  },
  methods: {
    ...mapMutations(["showmsg", "loading", "updateMenuState", "toogleMainDialog"]),
    async getTransactionList() {
      if (this.subscriptionId) {
        let params = {
          subscriptionId: this.subscriptionId,
        }
        this.loading(true)
        await this.subscriptionService.getTransactionList(params).then((response) => {
          this.addTransactions(response.data.records)
        }).catch(() => {
          this.showmsg({text: "Erro ao listar histórico de pagamento", type: "error"});
        }).finally(() => {
          this.loading(false)
        });
      } else {
        this.showmsg({text: "Não foi possível encontrar a assinatura.", type: "error"});
      }
    },
    addTransactions(records){
      records.forEach((record) => {
        this.transactions.push({
          id: record.id,
          date: moment(record.payday).format("MM/yyyy"),
          fullDate: moment(record.payday).format("DD/MM/yyyy"),
          value: this.$util.formatCurrencyToBR(record.value / 100),
          status: record.current_status,
          cardBrand: record.card_brand,
          cardNumber: record.card_number,
          statusDescription: record.current_status_description
        })
      })
    }

  },

  computed: {
    ...mapState(["menu", "app"]),
    menur: {
      get: function () {
        return this.menu.rigth;
      },
      set: function (value) {
        this.updateMenuState({ rigth: value });
      },
    },
  },

};
</script>

<style scoped>
.card-text{
  font-size: 28px !important;
  color: #532E88 !important;
  font-weight: bold !important;
  font-family: 'Open Sans' !important;
}
.text-alert{
  color: #532E88 !important;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Open Sans' !important;
}

</style>
